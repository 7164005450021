<template>
  <!-- background="linear-gradient(#21346f, #151b31)" -->
  <!-- :style="{backgroundImage:require('@/assets/images/bg/banner_bg_2.jpg')}" -->
  <!-- :style="{height: contentHeight + 'px',background:'linear-gradient(rgba(0,123,255,0.8), rgba(0,123,255, 0.8)),'+'url(' + bgImg + ') no-repeat',backgroundPosition:'50%',color:'green'}" -->
  <!-- :style="{height: contentHeight + 'px',background:item.backgroundColor||''+(item.backgroundImage?','+'url(' + item.backgroundImage + ')':',url()'),backgroundPosition:'50%'}" -->
  <b-carousel id="carousel-1" :interval="interval" :indicators="dataList.length>1">
    <b-carousel-slide
      v-for="(item,index) in dataList"
      :key="index"
      img-width="100%"
      img-height="600px"
      :style="{height: (isPhone?height/2:height) + 'px',background:`${(item.backgroundColor||'linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0, 0.1))')},url(${item.backgroundImage}) no-repeat`,backgroundSize:'cover',backgroundPosition:'center'}"
    >
      <!-- <div style="background:red;"> -->
      <div class="swiper-box" :style="{height: (isPhone?height/2:height)-50 + 'px'}">
        <div class="container">
          <div class="row">
            <div
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="50"
              data-aos-duration="800"
              :class="['col',item.image&&isPhone==false ? 'box-text-left' : 'box-text-center']"
            >
              <h2 v-if="item.title" :style="{fontSize:item.titleFontSize||'44px'}">{{item.title}}</h2>
              <p v-if="item.subTitle">{{item.subTitle}}</p>
              <div v-if="item.buttons&&item.buttons.length>0">
                <a :href="item2.href" v-for="(item2,index2) in item.buttons" :key="index2">
                  <b-button
                    :size="isPhone?'md':item2.size||'lg'"
                    :variant="item2.color||'success'"
                    style="margin: 0 10px 10px 0;"
                  >{{item2.text}}</b-button>
                </a>
              </div>
              <slot :name="index"></slot>
            </div>
            <div
              v-if="item.image"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="50"
              data-aos-duration="800"
              class="col iphone-tagger"
            >
              <img style="max-width:100%" :src="item.image" alt />
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import 'aos/dist/aos.css'
import AOS from 'aos/dist/aos.js'
export default {
  props: {
    dataList: {
      type: Array,
      default: () => {
        return {}
      }
    },
    height: {
      type: Number,
      default: 600
    },
    interval: {
      type: Number,
      default: 4000
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    isPhone() {
      return document.documentElement.clientWidth < 576
    }
  },
  created() {
    AOS.init({
      offset: 100,
      duration: 300,
      easing: 'ease-in-sine'
    })
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
  .iphone-tagger {
    display: none !important;
  }
  h2 {
    font-size: 24px !important;
  }
  .box-text-center {
    align-items: center !important;
  }
}
.carousel-item {
  width: 100%;
  .swiper-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // h2 {
    //    font-weight: bold;
    // }
    .box-text-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    .box-text-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
<style lang="scss">
#carousel-1 {
  .w-100 {
    height: 100% !important;
  }
}
</style>